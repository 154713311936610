
















































import { defineComponent, ref, computed, onMounted, onUnmounted, PropType } from '@nuxtjs/composition-api';
import { SfButton, SfLoader, SfSection, SfCarousel } from '@storefront-ui/vue';
import { useImage, useProduct } from '~/composables';
import useWishlist from '~/modules/wishlist/composables/useWishlist';
import { useUser } from '~/modules/customer/composables/useUser';
import { useAddToCart } from '~/helpers/cart/addToCart';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import SfProductCard from '~/components/JetUi/organisms/Product/SfProductCard.vue';
import { SortEnum } from '~/modules/GraphQL/types';
import type { Product } from '~/modules/catalog/product/types';

export default defineComponent({
  name: 'ProductShowcase',
  components: {
    SfSection,
    SfLoader,
    SfButton,
    SfCarousel,
    SfProductCard
  },
  props: {
    settings: {
      default: () => ({
        type: 'carousel',
        rewind: true,
        perView: 6,
        autoplay: 9000,
        hoverpause: true,
        gap: 30,
        breakpoints: {
          1920: {
            perView: 6,
            gap: 20,
            peek: {
              before: 0,
              after: 50,
            },
          },
          1600: {
            perView: 5,
            gap: 18,
            peek: {
              before: 0,
              after: 50,
            },
          },
          1400: {
            perView: 4,
            gap: 16,
            peek: {
              before: 0,
              after: 50,
            },
          },
          1200: {
            perView: 3,
            gap: 15,
            peek: {
              before: 0,
              after: 50,
            },
          },
          992: {
            perView: 2,
            gap: 12,
            peek: {
              before: 0,
              after: 50,
            },
          },
          768: {
            perView: 2,
            gap: 10,
            peek: {
              before: 0,
              after: 50,
            },
          },
          576: {
            perView: 1,
            gap: 8,
            peek: {
              before: 0,
              after: 50,
            },
          },
          480: {
            perView: 1,
            gap: 5,
            peek: {
              before: 0,
              after: 15,
            },
          },
        }
      }),
    },
    buttonText: {
      type: String,
      default: 'Ver Todos',
    },
    link: {
      type: String,
      default: '/',
    },
    title: {
      type: String,
      default: 'Produtos em Destaque',
    },
    category: {
      type: String,
      default: '',
    },
    skus: {
      default: () => [],
    },
    type: {
      default: 'category', // 'skus'
    },
    isCarousel: {
      default: false,
    },
    sort: {
      default: 'name', // 'name', 'position', 'price', 'relevance'
    },
    sortDirection: {
      type: String,
      default: 'ASC', // DSC
    },
    amount: {
      default: 2,
    },
    product: {
      type: Array as PropType<Product[]>,
      required: true,
    },
  },
  emits: ['click:wishlist'],
  setup(props) {
    const qty = ref(1);
    const { isAuthenticated } = useUser();
    const { getProductList, loading, getProductPath } = useProduct();
    const { isInWishlist, addOrRemoveItem } = useWishlist();
    const { addItemToCart, isInCart } = useAddToCart();
    const products = ref([]);
    const mappedProducts = computed(() => products.value.map((product) => ({
      ...product,
      isInWishlist: isInWishlist({ product }),
    })));
    const addItemToWishlist = async (product) => {
      await addOrRemoveItem({ product });
    };

    const { getMagentoImage, imageSizes } = useImage();
    const searchTerm = props.type === 'category' ? props.category : '';

    onMounted(async () => {
      const filter = props.category
        ? { category: props.category }
        : props.skus.length
          ? { skus: props.skus }
          : {};

      const sortType = props.sort;
      const sortDirection = props.sortDirection === 'ASC' ? SortEnum.Asc : SortEnum.Desc;

      const productList = await getProductList({
        search: searchTerm,
        pageSize: props.amount,
        currentPage: 1,
        sort: { [sortType]: sortDirection },
        ...filter,
      });

      if (productList?.items?.length) {
        const productsWithTypename = productList.items.map(product => ({
          ...product,
          typename: 'SimpleProduct',
        }));

        products.value = productsWithTypename;
      }
    });

    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const shouldShowCarousel = computed(() => {
      return windowWidth.value <= 1200 || props.isCarousel;
    });

    const getFlexStyle = () => {
      const breakpoints = props.settings.breakpoints;
      const width = window.innerWidth;
      let perView = 1;
      let gap = 25;

      for (const breakpoint in breakpoints) {
        if (width <= parseInt(breakpoint)) {
          perView = breakpoints[breakpoint].perView;
          gap = breakpoints[breakpoint].gap;
          break;
        }
      }

      return {
        display: 'flex',
        flexWrap: 'wrap',
        gap: `${gap}px`,
        justifyContent: perView === 1 ? 'center' : 'flex-start',
      };
    };

    const flexStyle = ref(getFlexStyle());

    onMounted(() => {
      window.addEventListener('resize', handleResize);
      handleResize();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
    });

    const receiveData = (product, quantity) => {
      addItemToCart({ product, quantity });
    };

    const handleQtyUpdate = (newQty: number) => {
      qty.value = newQty;
    };

    return {
      addItemToCart,
      addItemToWishlist,
      isAuthenticated,
      isInCart,
      isInWishlist,
      loading,
      mappedProducts,
      productGetters,
      getMagentoImage,
      imageSizes,
      getProductPath,
      flexStyle,
      receiveData,
      qty,
      handleQtyUpdate,
      shouldShowCarousel,
    };
  },
});
