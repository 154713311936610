












































































































































































import {
  defineComponent,
  ref,
  onMounted,
  useFetch,
  computed,
} from '@nuxtjs/composition-api';

import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { CmsPage } from '~/modules/GraphQL/types';
import { useContent } from '~/composables';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import ProductShowcase from '~/components/JetUi/organisms/Product/ProductShowcase.vue';
import BannerCarousel from '~/components/JetUi/organisms/Banner/BannerCarousel.vue';
import BenefitsDisplay from '~/components/JetUi/organisms/Benefits/BenefitsDisplay.vue';
import JetImage from '~/components/JetUi/atoms/JetImage/JetImage.vue';
import Bigbrands from '~/components/JetUi/molecules/bigbrands/bigbrands.vue';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import BankSlipModal from '~/modules/checkout/pages/Checkout/JetCheckout/JetCheckoutModals/BankSlipModal.vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    Bigbrands,
    SkeletonLoader,
    LoadWhenVisible,
    ProductShowcase,
    BannerCarousel,
    BenefitsDisplay,
    JetImage,
    BankSlipModal,
  },
  setup() {
    const { addTags } = useCache();
    const { loadPage } = useContent();
    const loaded = ref(false);

    const settings = {
      type: 'carousel',
      rewind: true,
      perView: 1,
      slidePerPage: true,
      gap: 0,
    };

    const page = ref < CmsPage | null >(null);
    const banners = ref([
      {
        image: 'https://www.espacial.com.br/media/wysiwyg/18.png',
        imageMobile: 'https://www.espacial.com.br/media/wysiwyg/mob.png',
        title: 'Banner1',
        link: 'https://www.google.com/',
      },
      {
        image: 'https://www.espacial.com.br/media/wysiwyg/16.png',
        imageMobile: 'https://www.espacial.com.br/media/wysiwyg/mob.png',
        title: 'Banner2',
        link: 'https://www.espacial.com.br/',
      },    
       {
        image: 'https://www.espacial.com.br/media/wysiwyg/14_1.png',
        imageMobile: 'https://www.espacial.com.br/media/wysiwyg/mob.png',
        title: 'Banner2',
        link: 'https://www.espacial.com.br/',
      },
    ]);

    useFetch(async () => {
      page.value = await loadPage({ identifier: 'home' });
    });

    onMounted(() => {
      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
      loaded.value = true;
    });

    const pageTitle = computed(() => page.value?.title || '');

    return {
      page,
      banners,
      settings,
      loaded,
      pageTitle,
    };
  },
  head() {
    const pageTitle = (this.page as CmsPage)?.title || '';
    return {
      title: this.$t(pageTitle).toString(),
    };
  },
});
